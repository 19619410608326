<style scoped>
.table {
  font-size: 0.7em;
}
.font-smaller {
  font-size: 0.8em;
}
</style>

<template>
  <div class="mt-3">
    <!-- Modal component to Edit User Info -->
    <user-info
      v-bind:user="editUser"
      v-bind:update-root-user="false"
      v-on:user-edited="getListResidents()"
    />
    <h4>Settings</h4>

    <!-- List of Users -->
    <h4 class="text-left mt-5 mb-2">List of Residents</h4>
    <b-table
      class="table mt-3"
      ref="residents"
      striped
      small
      hover
      :items="listResidents"
      :fields="fields"
      :key="listResidents.id"
      sort-by="Status"
      :sort-desc="true"
      selectable
      select-mode="single"
      v-on:row-selected="rowSelected($event)"
    >
      <template v-slot:cell(Provider)="data">{{ data.value ? "Yes":"No" }}</template>
      <template v-slot:cell(Status)="data">{{ role(data.value) }}</template>
    </b-table>
    <div class="text-right">
      <b-button variant="primary" @click="addUser()">Add Resident</b-button>
    </div>
  </div>
</template>

<script>
import UserInfo from "@/components/UserInfo.vue";
import moment from "moment";

export default {
  data() {
    return {
      listResidents: [],
      fields: [
        { key: "Name", label: "Name", sortable: true },
        { key: "Diet", label: "Diet", sortable: true },
        { key: "Provider", label: "Reminders" },
        {
          key: "Birthday",
          label: "Birthday",
          sortable: true,
          sortByFormatted: (a) => {
            return moment(a).format("MM-DD");
          },
          formatter: date => {
            return date != "0000-00-00" ? moment(date).format("MMM D") : "";
          }
        },
        { key: "Status", label: "Role", sortable: true }
      ],
      editUser: {
        id: 0,
        name: "",
        initials: "",
        password: "",
        diet: "",
        description: "",
        email: "",
        birthday: "",
        phone: "",
        provider: "",
        status: 0
      }
    };
  },
  computed: {},
  mounted() {
    this.getListResidents();
  },
  components: {
    UserInfo
  },
  methods: {
    getListResidents() {
      var url = "getListResidents.php";
      var data = {};
      this.$http.post(url, data, { emulateJSON: true }).then(res => {
        this.listResidents = res.body;
      });
    },
    rowSelected(e) {
      if (e.length > 0) {
        var sel = e[0];
        this.$set(this.editUser, "id", sel.id);
        this.$set(this.editUser, "name", sel.Name);
        this.$set(this.editUser, "initials", sel.UserName);
        this.$set(this.editUser, "password", sel.Password);
        this.$set(this.editUser, "diet", sel.Diet);
        this.$set(this.editUser, "description", sel.DietDescription);
        this.$set(this.editUser, "email", sel.Email);
        this.$set(this.editUser, "birthday", sel.Birthday);
        this.$set(this.editUser, "phone", sel.Phone);
        this.$set(this.editUser, "provider", sel.Provider);
        this.$set(this.editUser, "status", sel.Status);

        this.$bvModal.show("modal-user");
        this.$refs.residents.clearSelected();
      }
    },
    addUser() {
      this.$set(this.editUser, "id", "");
      this.$set(this.editUser, "name", "");
      this.$set(this.editUser, "initials", "");
      this.$set(this.editUser, "password", "");
      this.$set(this.editUser, "diet", "");
      this.$set(this.editUser, "description", "");
      this.$set(this.editUser, "email", "");
      this.$set(this.editUser, "birthday", "");
      this.$set(this.editUser, "phone", "");
      this.$set(this.editUser, "provider", "");
      this.$set(this.editUser, "status", 0);

      this.$bvModal.show("modal-user");
    },
    role(status) {
      switch (status) {
        case "1":
          return "Resident";
        case "3":
          return "Resident - Edit Notes";
        case "0":
          return "Administration";
        case "7":
          return "Priest";
        case "15":
          return "Director";

        default:
          return "";
      }
    }
  }
};
</script>
